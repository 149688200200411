body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat Regular';
  src: local('Montserrat Regular'), url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat Medium';
  src: local('Montserrat Medium'), url(./fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat Bold';
  src: local('Montserrat Bold'), url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat ExtraBold';
  src: local('Montserrat ExtraBold'), url(./fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}